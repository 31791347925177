import { createTheme } from '@mui/material';

export const theme = createTheme({

    palette: {
        primary: {
            light: '#3a424d',
            main: '#1c2025',
            dark: '#0a0b0d',
            contrastText: '#999',
        },
        secondary: {
            light: '#ffa733',
            main: '#ff9900',
            dark: '#cc6600',
            contrastText: '#999',
        },
    },

    typography: {
        fontFamily: [
            'Oswald',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#3a424d',
                    color: '#999',
                    textTransform: 'uppercase'
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "main" },
                    style: {
                        color: '#ccc',
                        borderBottom: '0px solid #ff9900',
                        transition: 'all 0.1s linear',
                        '&:hover': {
                            color: '#ff9900',
                            borderBottom: '4px solid #ff9900',
                        }
                    }
                }
            ]
        }
    }
});

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        main: true;
    }
}