import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { Content, Footer, Head } from './components';
import { theme } from './other/Theme';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Head />
      <BrowserRouter>
        <Content />
      </BrowserRouter>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
