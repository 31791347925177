import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { MenuItem } from 'types/app';

const navItems: Array<MenuItem> = [
    { label: 'CONCERTS//MUSICA', link: '/' },
    { label: 'LANDS//ARQUITETURA', link: '/landscape' },
    { label: 'MISC//OUTROS', link: '/misc' },
    { label: 'ABOUT//SOBRE', link: '/about' }
]


export function Head() {

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.label} disablePadding >
                        <ListItemButton href={item.link} sx={{ textAlign: 'center' }} >
                            <ListItemText >{item.label}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );



    return (
        <Box >
            <AppBar component="nav">

                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }} >

                    <h1>
                        <a href='/'>Henrique/Photos</a>
                    </h1>


                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        sx={{ display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>


                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>

                        {navItems.map((item) => (
                            <Button
                                variant='main'
                                href={item.link}
                                key={item.label}
                                sx={{ color: '#fff', fontSize: '16px', fontWeight: 300 }}
                            >
                                {item.label}
                            </Button>
                        ))}


                    </Box>
                </Toolbar>
            </AppBar>

            <Box component="nav" >
                <Drawer
                    color='dark'
                    anchor="right"
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}

                >
                    {drawer}
                </Drawer>
            </Box>

        </Box >
    );
}
