import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import { GalleryInfo } from 'types/app';
import { useParams } from 'react-router-dom';
import Image from 'mui-image';
import FsLightbox from 'fslightbox-react';

type ShowcaseProps = {
    setError: (val: string) => void;
}

export const Gallery: React.FC<ShowcaseProps> = ({ setError }): ReactElement => {

    const [galleryItems, setGalleryItems] = useState<GalleryInfo>();
    const [loading, setLoading] = useState<boolean>(true);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    let { category = 'concerts', gallery } = useParams();

    const openLightboxOnSlide = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, i: number) => {
        e.preventDefault();
        const newIndex: number = (i + 1)
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: newIndex
        });
    }


    useEffect(() => {
        const host: string = 'https://henrique.photos/service/'
        const service: string = 'getGallery.php'
        setLoading(true);
        axios
            .get<GalleryInfo>(host + service, {
                params: { gallery }
            })
            .then(response => {
                setGalleryItems(response.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err.response.data.error);
            })
    }, [category, gallery, setError]);

    if (!loading) {
        if (galleryItems) {
            const items = galleryItems.images.map((i, idx) => (
                <div key={"it" + idx}  >
                    <a href={i} onClick={(e) => openLightboxOnSlide(e, idx)} >
                        <Image src={i} showLoading />
                    </a>
                </div>
            ));

            return (
                <div className='gallery' id='photoGallery'>
                    <h2>{galleryItems.title}</h2>
                    <div className='galleryDisplay'>
                        {items}
                        <FsLightbox
                            toggler={lightboxController.toggler}
                            type="image"
                            sources={galleryItems.images}
                            slide={lightboxController.slide}
                        />
                    </div>
                </div>
            )
        }
    }

    return (<></>)

}