import { Box, Button, ButtonGroup } from '@mui/material';
import React, { ReactElement } from 'react';

export const About: React.FC = (): ReactElement => {

    return (<div className='aboutbox'>
        <h3>About</h3>

        <p>Hello there, </p>
        <p>Currently based in Berlin, natural from São Paulo.</p>
        <p>Natural photographer especilized in concerts and festivals photography and portrait.</p>
        <p>Currently I dedicate myself to portrait and people photography, making books and rehearsals for models, actresses, bands, musicians or for anyone who has a good idea that can be expressed through photos. And if you don't have an idea yet, let's talk and think of one together.</p>
        <p>You can know and follow my work on my social-media:</p>
        <br />

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                    m: 1,
                },
            }}
        >
            <ButtonGroup variant="text" aria-label="text button group" color='secondary'>
                <Button size='large' color="secondary" href='https://www.facebook.com/HenriquePimentelZHP' >
                    <i className='fa fa-facebook-official fa-lg fa-fw' ></i>
                </Button>
                <Button variant='text' size='large' color="secondary" href='https://flickr.com/zapbr' >
                    <i className='fa fa-flickr fa-lg fa-fw' ></i>
                </Button>
                <Button size='large' color="secondary" href='https://instagram.com/te_retrato' >
                    <i className='fa fa-instagram fa-lg fa-fw' ></i>
                </Button>
                <Button size='large' color="secondary" href='https://500px.com/zapbr' >
                    <i className='fa fa-500px fa-lg fa-fw' ></i>
                </Button>
            </ButtonGroup>
        </Box>
    </div>)

}
