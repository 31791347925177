import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import { Page } from 'types/app';
import { Link, useParams } from 'react-router-dom';
import Image from 'mui-image';
import { shuffle } from 'src/other/shuffle';

type ShowcaseProps = {
    setError: (val: string) => void;
}

export const Showcase: React.FC<ShowcaseProps> = ({ setError }): ReactElement => {


    let { category = 'concerts', gallery } = useParams();

    console.log({ category })
    console.log({ gallery })

    const [page, setPage] = useState<Page>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {

        const host: string = 'https://henrique.photos/service/'
        const service: string = 'getPage.php'

        axios
            .get<Page>(host + service, {
                params: { category }
            })
            .then(response => {
                console.log({ response })
                const rnd: Page = { items: shuffle(response.data.items) }
                setPage(rnd);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError(err.response.data.error);
            })
    }, [category, setError]);


    if (!loading) {
        if (page) {
            const items = page.items.map((i, idx) => (
                <div key={"it" + idx} className='showCaseItem' >
                    <h2>{i.title}</h2>
                    <Link to={`/${category}/${i.link}`}  >
                        <Image src={i.image} showLoading />
                    </Link>
                </div >
            ));

            return (
                <div className='showCase'  >
                    {items}
                </div>
            )
        }
    }

    return (<></>)

}