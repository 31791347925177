import React, { ReactElement, useState } from 'react';
import { Showcase } from './Showcase';
import { Route, Routes } from 'react-router-dom';
import { About } from './About';
import { Gallery } from './Gallery';

export const Content: React.FC = (): ReactElement => {

    const [error, setError] = useState<string>('');
    return (
        <main>
            <p className='error'>{error}</p>
            <Routes>
                <Route path="/" element={<Showcase setError={setError} />} />
                <Route path="/:category" element={<Showcase setError={setError} />} />
                <Route path="/:category/:gallery" element={<Gallery setError={setError} />} />
                <Route path="/about" element={<About />} />
            </Routes>
        </main>
    )
}
